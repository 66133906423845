import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { initializeIcons } from '@uifabric/icons';
import * as React from 'react';
import { hot } from 'react-hot-loader';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import Layout from '../components/common-layout/layout/layout';
import { ExtensionInformation } from '../components/extension-information/extension-information';
import ExtensionHome from '../components/home/extension-home';
import Home from '../components/home/home';
import PluginHome from '../components/home/plugin-home';
import SearchView from '../components/home/search/search-view';
import InitiateScan from '../components/ondemand-scan/initiate-scan/initiate-scan';
import InitiateScanResponse from '../components/ondemand-scan/initiate-scan/initiate-scan-result';
import ScanStatus from '../components/ondemand-scan/scan-status/scan-status';
import ScanStatusResult from '../components/ondemand-scan/scan-status/scan-status-result';
import ViewScanIds from '../components/productscan-information/productscan-info-by-scanid/view-scanIds';
import ProductScanInformation from '../components/productscan-information/productscan-information';
import Report from '../components/report/report';
import ViewPluginSummary from '../components/report/view-plugin-summary';
import ViewSummary from '../components/report/view-scan-report';
import Rule from '../components/rule/rule';
import { CreateBySubmissionId } from '../components/submit/create/create-by-submissionid';
import Submit from '../components/submit/submit';
import View from '../components/view/view';
import { ViewReport } from '../components/view/view-report/view-report';
import { msalConfig } from '../configurations/configuration';
import Routes from './routes';
import ProductInformation from '../components/product-information/product-information';
import ProductInformationResult from '../components/product-information/product-information-result';
import { ProductType } from '../lib/enums/common-enums';

initializeIcons();

// URL format
const Root: React.FC<unknown> = () => {
  return (
    <MsalProvider instance={new PublicClientApplication(msalConfig)}>
      <BrowserRouter>
        <Switch>
          <Layout>
            <Route exact={true} path={`/${Routes.Home}`} component={Home} />
            <Route exact={true} path={`/${ProductType.Extension}/${Routes.Home}`} component={ExtensionHome} />
            <Route exact={true} path={`/${ProductType.Extension}/${Routes.ThreatAnalysisRecordCreate}/:submissionId/:scanId/:publisher`} component={Submit} />
            <Route exact={true} path={`/${ProductType.Extension}/${Routes.ThreatAnalysisRecordCreate}/:submissionId`} component={Submit} />
            <Route exact={true} path={`/${ProductType.Extension}/${Routes.ThreatAnalysisRecordCreate}`} component={CreateBySubmissionId} />
            <Route exact={true} path={`/${ProductType.Extension}/${Routes.ThreatAnalysisRecordSearch}/:reportId`} component={View} />
            <Route exact={true} path={`/${ProductType.Extension}/${Routes.ThreatAnalysisRecordSearch}/`} component={ViewReport} />
            <Route exact={true} path={`/${ProductType.Extension}/${Routes.ThreatAnalysisRecordEdit}/:reportId`} component={Submit} />
            <Route exact={true} path={`/${ProductType.Extension}/${Routes.ThreatAnalysisRecordEdit}`} component={Home} />
            <Route exact={true} path={`/${ProductType.Extension}/${Routes.ScanReport}`} component={Report} />
            <Route exact={true} path={`/${ProductType.Extension}/${Routes.ScanReport}/:scanType/:scanId/:scanner`} component={ViewSummary} />
            <Route exact={true} path={`/${ProductType.Extension}/${Routes.Search}`} component={SearchView}/>
            <Route exact={true} path={`/${ProductType.Extension}/${Routes.Information}`} component={ExtensionInformation} />
            <Route exact={true} path={`/${ProductType.Extension}/${Routes.InformationBySubmissionId}/:submissionId`} component={ExtensionInformation} />
            <Route exact={true} path={`/${ProductType.Extension}/${Routes.InformationByCrxId}/:crxId`} component={ExtensionInformation} />
            <Route exact={true} path={`/${ProductType.Extension}/${Routes.Rules}`} component={Rule} />
            <Route exact={true} path={`/${ProductType.Extension}/${Routes.Rules}/:ruleId`} component={Rule} />
            <Route exact={true} path={`/${ProductType.Extension}/${Routes.InitiateScan}`} component={InitiateScan} />
            <Route exact={true} path={`/${ProductType.MicrosoftCopilotPlugin}/${Routes.Home}`} component={PluginHome}/>
            <Route exact={true} path={`/${ProductType.MicrosoftCopilotPlugin}/${Routes.ScanReport}`} component={Report} />
            <Route exact={true} path={`/${ProductType.MicrosoftCopilotPlugin}/${Routes.ScanReport}/:scanType/:scanId/:scanner`} component={ViewPluginSummary} />
            <Route exact={true} path={`/${ProductType.MicrosoftCopilotPlugin}/${Routes.ScanInformation}`} component={ProductScanInformation} />
            <Route exact={true} path={`/${ProductType.MicrosoftCopilotPlugin}/${Routes.ScanInformation}/:productId/:validationId?`} component={ViewScanIds} />
            <Route exact={true} path={`/${ProductType.MicrosoftCopilotPlugin}/${Routes.ScanStatus}`} component={ScanStatus} />
            <Route exact={true} path={`/${ProductType.MicrosoftCopilotPlugin}/${Routes.ScanStatus}/:productId/:validationId`} component={ScanStatusResult} />
            <Route exact={true} path={`/${ProductType.MicrosoftCopilotPlugin}/${Routes.ScanReport}/:scanId`} component={Report}/>
            <Route exact={true} path={`/${ProductType.MicrosoftCopilotPlugin}/${Routes.InitiateScan}/:productId/:productVersion`} component={InitiateScanResponse}/>
            <Route exact={true} path={`/${ProductType.MicrosoftCopilotPlugin}/${Routes.Information}/productType/:productType/id/:productId/version/:productVersion`} component={ProductInformationResult}/>
            <Route exact={true} path={`/${ProductType.MicrosoftCopilotPlugin}/${Routes.Information}`} component={ProductInformation}/>
            <Route exact path={`/`} render={() => <Redirect to={`/${Routes.Home}`} />} />
          </Layout>
        </Switch>
      </BrowserRouter>
    </MsalProvider>
  );
};

export default hot(module)(Root);

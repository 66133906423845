import * as React from 'react';
import GenericErrorScreen from '../../error-screens/generic-error-screen';
import localeStrings from '../../../../assets/localeStrings/english.json';
import useAxiosPost, { ApiCallStatus } from '../../hooks/useAxiosPost';
import useInput from '../../hooks/useInputValidation';
import { ExtensionThreatAnalysisLogin } from '../../../configurations/configuration';
import { ExtensionThreatSummaryApis } from '../../../configurations/url-configs';
import { IInitiateScanResponse } from '../../../lib/interfaces/common-interface';
import {
  PrimaryButton,
  Spinner,
  SpinnerSize,
  TextField
  } from '@fluentui/react';
import { URLCreator } from '../../../lib/url-creator/url-creator';
import { SubmissionIdValidation } from "../../../lib/utils/util";
import './initiate-scan.scss';
import '../../../html-constants/styles/html-styles';
import { useMsal } from '@azure/msal-react';

export default function InitiateScan(): React.ReactElement {
  const { instance, accounts } = useMsal();
  const { apiCallStatus, error, postFunc, result } = useAxiosPost<IInitiateScanResponse>(
    new URLCreator(ExtensionThreatSummaryApis.InitiateScanByProductIdAndVersion).GetUrl(),
    ExtensionThreatAnalysisLogin,
  );
   
    const submissionIdInput = useInput(
        localeStrings[`EnterSubmissionId`],
        true,
        localeStrings[`SubmissionIdErrorText`],
        SubmissionIdValidation
    );

  const getScanInitiator = () => {
    return instance.getAccountByHomeId(accounts[0]?.homeAccountId)?.idTokenClaims?.name;
  }

  if (apiCallStatus === ApiCallStatus.Loading){
    return <Spinner size={SpinnerSize.medium} />;
  } else if (apiCallStatus === ApiCallStatus.Failed || error !== null) {
    return <GenericErrorScreen error={error} />;
  }

  if(apiCallStatus === ApiCallStatus.Succeeded){
      return <h2>Retriggered workflow successfully</h2>;
  }

  return (
    <div className="outer-div">
      <h2 className="title">{localeStrings[`InitiateScanTitle`]}</h2>
      <hr />
        <div className="input-div">
          <h4>{localeStrings[`EnterSubmissionId`]}</h4>
          <TextField
            placeholder={localeStrings[`SubmissionId`]}
            {...submissionIdInput}
            iconProps={{ iconName: "search" }}
          />
          <hr/>
          <div>
            <PrimaryButton className="button"
              onClick={() => postFunc({
                SubmissionId: submissionIdInput.value,
                ScanInitiator: getScanInitiator(),
              })}
              disabled={!submissionIdInput.isValidInput}>
              {localeStrings[`Submit`]}
            </PrimaryButton>
          </div>
        </div>
    </div>
  );
}
